
























































































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import DetailModal from '@/components/DetailModal.vue'
import moment from 'moment'
import { TaskController } from '@/services/request.service'
import FlexTooltip from '@/components/FlexTooltip.vue'
import cloneDeep from 'lodash/cloneDeep'
import { handleBatchDownload } from '@/utils/utils'
import { SimditorVue } from '@/components/simditor/Simditor'

@Component({
  components: {
    DetailModal,
    FlexTooltip,
    SimditorVue,
  },
})
export default class AssignmentDetail extends Vue {
  private assignmentInfo: any = {}
  private detailInfo: any = {
    title: '',
    infoList: [],
  }
  private assignmentId: any
  private detailModalShow: boolean = false
  private descFold = false
  private descFoldShow = false
  private docViewVis: any = false
  private docUrl: any = ''
  private docType: any = ''
  private drawerInfo: any = {}
  private drawerLoading: boolean = false
  private drawerVisible: boolean = false
  private editStudent: any = null
  private editTaskId: any = null
  private exportable: any = false
  private downloading: any = false
  private lastVisible = false
  private loading: boolean = false
  private studentData: any = []
  private studentMenuKey: any = []
  private moment = moment
  private nextVisible = false
  private updateLoading = false

  private get operationAuths(): any {
    return this.$store.state.operationAuths
  }

  private get columns(): any {
    return [
      {
        dataIndex: 'studentName',
        key: 'student',
        title: this.$t('common.student'),
        scopedSlots: { customRender: 'student' },
      },
      {
        dataIndex: 'studentNum',
        key: 'studentNum',
        title: this.$t('common.studentId'),
        ellipsis: true,
        scopedSlots: { customRender: 'studentId' },
      },
      {
        dataIndex: 'className',
        title: this.$t('common.homeroom'),
        ellipsis: true,
        scopedSlots: { customRender: 'homeroom' },
      },
      {
        dataIndex: 'houseName',
        title: this.$t('common.advisory'),
        ellipsis: true,
        scopedSlots: { customRender: 'advisory' },
      },
      this.assignmentInfo.online
        ? [
            {
              dataIndex: 'status',
              key: 'submitStatus',
              title: this.$t('assignment.submitStatus'),
              scopedSlots: { customRender: 'submitStatus' },
            },
            {
              dataIndex: 'handInTime',
              title: this.$t('assignment.submitTime'),
              scopedSlots: { customRender: 'submitTime' },
            },
          ]
        : undefined,
      this.assignmentInfo.online || this.assignmentInfo.scoreFlag
        ? {
            key: 'score',
            width: 200,
            title: this.$t('common.score'),
            scopedSlots: { customRender: 'score' },
            customCell: () => ({ class: 'dynamic-cell' }),
          }
        : undefined,
    ]
      .filter(item => item)
      .flat()
  }
  private get excelColumns(): Array<any> {
    return [
      {
        dataIndex: 'lastName',
        title: this.$t('common.surname'),
        width: 10,
      },
      {
        dataIndex: 'enName',
        title: this.$t('common.enName'),
        width: 10,
      },
      {
        dataIndex: 'firstName',
        title: this.$t('common.givenName'),
        width: 10,
      },
      {
        dataIndex: 'name',
        title: this.$t('common.cnName'),
        width: 10,
      },
      {
        dataIndex: 'studentNum',
        title: this.$t('common.studentId'),
        width: 15,
      },
      {
        dataIndex: 'gender',
        title: this.$t('myClass.student.gender'),
        width: 15,
      },
      {
        dataIndex: 'sectionName',
        title: this.$t('common.grade'),
        width: 15,
      },
      {
        dataIndex: 'className',
        title: this.$t('common.homeroom'),
        width: 20,
      },
      {
        dataIndex: 'houseGroupName',
        title: this.$t('common.advisory'),
        width: 20,
      },
    ]
  }
  private get infoList(): any {
    return [
      this.type === 'subjectClass' && {
        label: this.$t('common.subject'),
        value: this.assignmentInfo.subjectName,
      },
      {
        label: this.$t(`common.${this.type}`),
        value: this.assignmentInfo.course?.description,
      },
      {
        label: this.$t('common.type'),
        value: this.assignmentInfo.type?.name,
      },
      {
        label: this.$t('assignment.startDate'),
        value: this.assignmentInfo.startDate
          ? moment(this.assignmentInfo.startDate).format('YYYY.MM.DD HH:mm')
          : '',
      },
      {
        label: this.$t('assignment.deadline'),
        value: this.assignmentInfo.endDate
          ? moment(this.assignmentInfo.endDate).format('YYYY.MM.DD HH:mm')
          : '',
      },
      {
        label: this.$t('assignment.online1'),
        value: this.$t(`common.${this.assignmentInfo.online ? true : false}`),
      },
      this.assignmentInfo.online && {
        label: this.$t('assignment.handInAfterDead'),
        value: this.$t(`assignment.${this.assignmentInfo.overDeadline ? 'allow' : 'disallow'}`),
      },
      {
        label: this.$t('assignment.scoreAllow'),
        value: this.$t(`assignment.${this.assignmentInfo.scoreFlag ? 'allow' : 'disallow'}`),
      },

      this.assignmentInfo.scoreFlag && [
        {
          label: this.$t('assignment.topScore'),
          value: this.assignmentInfo.topScore || 0,
        },
        {
          label: this.$t('assignment.totalIncluded'),
          value: this.$t(`common.${this.assignmentInfo.inTotal ? true : false}`),
        },
        {
          label: this.$t('assignment.publicScore'),
          value: this.$t(`common.${!!this.assignmentInfo.publicFlag}`),
        },
      ],
      // {
      //   label: this.$t('common.description'),
      //   value: this.assignmentInfo.description
      // }
    ]
      .filter(item => item)
      .flat(1)
  }
  private get markList(): any {
    return [
      {
        key: 'missing',
        label: this.$t('grading.scoreSheet.mark.missing'),
      },
      {
        key: 'incomplete',
        label: this.$t('grading.scoreSheet.mark.incomplete'),
      },
      {
        key: 'late',
        label: this.$t('grading.scoreSheet.mark.late'),
      },
    ]
  }
  private get type(): any {
    return this.$route.query.assignType
  }

  private get dateShow(): any {
    return this.$route.query.dateShow
  }

  private get currentSchoolYear(): any {
    return this.$store.state.currentSchoolYear
  }

  private calcDate(date): any {
    const formatDate = moment(date).format('YYYY-MM-DD')
    return this.$t(`myClass.1017`, { date: formatDate })
  }

  private calcExpand(): void {
    this.$nextTick(() => {
      if (this.descFoldShow) return
      this.descFoldShow = false
      let el = document.getElementById(`aDescContent`)
      if (!el) return
      let ch = el.clientHeight
      let sh = el.scrollHeight
      this.descFoldShow = sh > ch
    })
  }

  private changeMenu({ item, key, keyPath }): void {
    this.studentMenuKey = [key]
    let idx = this.studentData.findIndex(item => item.taskStudentId === key)
    this.setDetail(
      this.studentData[idx],
      idx,
      this.assignmentInfo.scoreFlag && this.operationAuths.includes('2086')
    )
  }

  private async closeDrawer(needSave): Promise<any> {
    if (needSave && this.operationAuths.includes('2086')) {
      const result = await this.saveScore()
      if (result !== 'success') return
    }
    this.drawerInfo = {}
    this.drawerVisible = false
    this.editStudent = undefined
  }

  private exportAttach(): void {
    this.downloading = true
    const name = `${this.assignmentInfo.name}_Assignment.zip`
    let arr = [] as any
    this.studentData.forEach((student, index) => {
      arr = arr.concat(
        student.resources.map((item, index) => ({
          url: item.resourceUrl,
          name: this.createFileName(
            item.resourceName,
            student.studentName,
            index === 0 ? '' : index + 1
          ),
        }))
      )
    })
    handleBatchDownload(arr, name, () => {
      this.downloading = false
    })
  }

  private getAssignmentInfo(): void {
    TaskController.detail(this.assignmentId)
      .then(res => {
        this.assignmentInfo = res.data
        this.calcExpand()
      })
      .catch(err => console.log(err))
  }

  private getStudentInfos(): void {
    TaskController.getPerformance(this.assignmentId)
      .then(res => {
        this.studentData = res.data
        this.exportable = !!this.studentData.filter(student => student.resources.length).length
      })
      .catch(err => console.log(err))
  }

  private getStudentDetialInfo(studentId): void {}

  private preview(file): void {
    const microsoft = ['.doc', '.xls', '.xlsx', '.docx', '.ppt', '.pptx']
    const normal = ['.pdf', '.png', '.jpg', '.jpeg', '.gif']
    const video = ['.mov', '.mp4', '.mp3', '.mp5']
    const suffixArray = microsoft.concat(normal, video)
    const index = file.resourceName.lastIndexOf('.')
    if (index === -1) {
      this.$aDownload(
        file.resourceUrl,
        this.createFileName(file.resourceName, this.drawerInfo.name, '')
      )
      return
    }
    const suffix = file.resourceName.slice(index).toLowerCase()
    if (suffixArray.includes(suffix)) {
      if (microsoft.includes(suffix) && file.resourceSize >= 10 * 1024 * 1024) {
        this.$aDownload(
          file.resourceUrl,
          this.createFileName(file.resourceName, this.drawerInfo.name, '')
        )
        return
      }
      this.docViewVis = true
      this.docUrl = microsoft.includes(suffix)
        ? `https://view.officeapps.live.com/op/view.aspx?src=${file.resourceUrl}`
        : file.resourceUrl
      this.docType = video.includes(suffix) ? 'video' : 'doc'
    } else {
      this.$aDownload(
        file.resourceUrl,
        this.createFileName(file.resourceName, this.drawerInfo.name, '')
      )
    }
  }

  private async lastStudent(index): Promise<any> {
    if (index < 0) return
    const student = this.studentData[index]

    this.setDetail(
      student,
      index,
      this.assignmentInfo.scoreFlag && this.operationAuths.includes('2086')
    )
  }

  private async nextStudent(index): Promise<any> {
    if (index === this.studentData.length) return
    const student = this.studentData[index]

    this.setDetail(
      student,
      index,
      this.assignmentInfo.scoreFlag && this.operationAuths.includes('2086')
    )
  }

  private onMarkChange(key): void {
    this.drawerInfo.mark = key === this.drawerInfo.mark ? '' : key
  }

  private ignoreTip({ taskStudentId }): void {
    TaskController.clearReSubmit(taskStudentId)
      .then(res => {
        this.$message.success(this.$tc('common.ignoreSuccess'))
        this.getStudentInfos()
      })
      .catch(err => {
        console.error(err)
      })
  }

  private saveScore(): any {
    return new Promise(resolve => {
      ;(this.$refs['drawerForm'] as any).validate(valid => {
        if (valid) {
          const condition = {
            studentId: this.drawerInfo.id,
            taskId: this.assignmentId,
            score: this.drawerInfo.score === 0 ? 0 : this.drawerInfo.score || undefined,
            tag: this.drawerInfo.mark || 'none',
            comment: this.drawerInfo.comment,
          }
          const taskStudentId = this.drawerInfo.taskStudentId
          TaskController.updateScore(condition)
            .then(res => {
              let student = this.studentData.find(item => item.taskStudentId === taskStudentId)
              student.tag = this.drawerInfo.mark
              student.score = this.drawerInfo.score
              student.comments = this.drawerInfo.comment
              this.$message.success(this.$tc('tips.updateScoreSuccess'))
            })
            .catch(err => console.log(err))
            .finally(() => {
              this.updateLoading = false
              resolve('success')
            })
        } else {
          resolve('validateFailed')
          return false
        }
      })
    })
  }

  private async setDetail(student, index, needSave): Promise<any> {
    this.docViewVis = false
    if (needSave) {
      const result = await this.saveScore()
      if (result !== 'success') return
    }
    this.studentMenuKey = [student.taskStudentId]
    this.nextVisible = index !== this.studentData.length - 1
    this.lastVisible = index !== 0
    this.editStudent = cloneDeep(student)
    this.drawerLoading = true

    TaskController.getStudentTaskDetail(student.taskStudentId)
      .then(res => {
        this.drawerVisible = true
        const title = this.assignmentInfo.name
        this.drawerInfo = {
          id: res.data.studentId,
          title,
          index,
          name: student.studentName,
          content: res.data.content || 'Empty...',
          attachments: res.data.resources,
          score: res.data.score,
          mark: res.data.tag,
          comment: res.data.comments,
          taskStudentId: student.taskStudentId,
          max: this.assignmentInfo.topScore,
          isEdit: student.editFlag,
        }
      })
      .catch(err => console.log(err))
      .finally(() => (this.drawerLoading = false))
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(to): void {
    this.assignmentId = to.params.id
    this.getAssignmentInfo()
    this.getStudentInfos()
  }

  private createFileName(resourceName, studentName, fIndex): any {
    const index = resourceName.lastIndexOf('.')
    // const name = resourceName.slice(0,index)
    const suffix = resourceName.slice(index)
    return `${this.assignmentInfo.name}_${studentName}${fIndex ? `(${fIndex})` : ''}${suffix}`
  }

  private editTask(): void {
    this.$router.push({
      name: 'assignmentEditor',
      query: {
        type: 'edit',
        assignType: this.type,
        id: this.assignmentId,
        dateShow: this.dateShow,
      },
    })
  }
}
